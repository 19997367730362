<select {name} on:input on:blur on:change bind:value {...$$restProps}>
  <option value="">(Skip this column)</option>
  {#each options as option}
    <option value={option.value}>{option.label}</option>
  {/each}
</select>

<script type="ts">
  export let name = 'student_import[columns][]'
  export let options: String[]
  export let value
</script>
