<tr class="select_predefined_columns">
  <th class="row_1_type" />
  <th class="row_number" />
  {#each Array(data_column_count) as _, i}
    <th class="predefined_column">
      <SelectColumn
        {name}
        {options}
        bind:value={selected_values[i]}
        on:input={({ target: { value } }) => handleChange(value)}
      />
    </th>
  {/each}
</tr>

<!-- <tr>
  <td colspan={user_defined_column_names.length}>
    <div>user_defined_column_names: {JSON.stringify(user_defined_column_names, null, 2)}}</div>
    <div>selected_values: {JSON.stringify(selected_values, null, 2)}}</div>
  </td>
</tr> -->
<script type="ts">
  import SelectColumn from './SelectColumn.svelte'

  export let name: String
  export let options: SelectOption[]

  let supplied_selected_values: String[]
  export { supplied_selected_values as selected_values }
  // Convert null values to "" so that the blank <option> will actualy be get
  // selected, so that a blank placeholder value actually get submitted.
  // Tested by: rspec spec/features/student_import_spec.rb -t blank_columns_act_as_placeholders
  let selected_values = supplied_selected_values.map((value) => (value ? value : ''))

  export let data_column_count: number

  function handleChange(new_value) {
    selected_values.forEach((previous_selected_value, i) => {
      if (new_value && previous_selected_value == new_value) {
        selected_values[i] = ''
      }
    })
  }
</script>
