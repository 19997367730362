<svelte:component
  this={TbodyOrTheadComponent}
  bind:el={tbody}
  class="csv_row possible_header_row"
  data-row-number={row_number}
>
  <tr class="csv_row possible_header_row {row_1_type}" bind:this={tr}>
    <th class="row_1_type">
      <RowType
        name={row_1_type_name}
        bind:value={row_1_type}
        on:input={({ target: { value } }) => handleChangeRow1Type(value)}
      />
    </th>
    <th class="row_number">{row_number}.</th>

    {#each data_row as value, i}
      <td class="data_value">
        {value}
      </td>
    {/each}
  </tr>
</svelte:component>

<script type="ts">
  // This represents row 1 of the import file, which may be a header_row or data_row
  // This component exists to let the user switch between those, which of course
  // makes the row change visually to _look_ like whichever type of row they
  // have selected.

  import { onMount } from 'svelte'

  import { Tbody } from 'svelte-elements'
  import { Thead } from 'svelte-elements'
  import RowType from './RowType.svelte'

  export let row_number: number
  export let data_row: String[]
  export let row_1_type_name: String = 'student_import[row_1_type]'
  export let row_1_type: String

  type TbodyOrTheadElement = HTMLTbodyElement | HTMLTheadElement
  let tbody: TbodyOrTheadElement
  let TbodyOrTheadComponent: Component = getTbodyOrTheadComponent(row_1_type)
  let tr: HTMLTrElement

  onMount(() => {
    // Strangely, as soon as we wrap the <tr> in a <svelte:component>, tr is still undefined here in onMount. (It's supposed to be defined by the time onMount is called.)
    console.log('mounted', tbody, tr)
    handleChangeRow1Type(row_1_type)
  })

  function getTbodyOrTheadComponent(type) {
    if (type == 'header_row') {
      return Thead
    } else {
      return Tbody
    }
  }

  function handleChangeRow1Type(type) {
    if (!tr) return
    console.log('handleChangeRow1Type(', type, tr)
    const old_type = ['header_row', 'data_row'].filter((_) => _ !== type)[0]
    tr.classList.replace(old_type, type)
    TbodyOrTheadComponent = getTbodyOrTheadComponent(type)
  }
</script>
