import WebpackerSvelte from 'webpacker-svelte'

import { default as StudentImport } from '$svelte/student_import/index'

declare global {
  interface Window {
    webpacker_svelte_initialized?: boolean
  }
}

// Work around a strange behavior where this file gets loaded 2 times (at least in dev), and the 2nd time it causes an error because the components have already been registered.
console.log(
  'svelte/index.ts: start:',
  { webpacker_svelte_initialized: window.webpacker_svelte_initialized }
  // 'registeredComponents',
  // WebpackerSvelte.registeredComponents
)
if (!window.webpacker_svelte_initialized) {
  WebpackerSvelte.setup({
    ...StudentImport,
  })

  console.log('svelte/index.ts: finished: registeredComponents', WebpackerSvelte.registeredComponents)
  window.webpacker_svelte_initialized = true
}
